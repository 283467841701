<template>
    <SmartForm
        base_url="/settings/activity"
        base_path="/settings/activity"
        form_type="edit"
    ></SmartForm>
</template>

<script>
 import SmartForm from "@/view/components/SmartForm.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartForm,
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Discipline", route: "/settings/activity/index" },
             { title: "Modifica Disciplina" }
         ]);
     }
 };
</script>
